import React, { useState, useEffect, useRef } from 'react';
import { Table, InputNumber, Select, Button, Space, Typography, Row, Col } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const { Option } = Select;
const { Text } = Typography;

const BoxDimensionsForm = ({
  requiredTotalQuantity,
  requiredTotalWeight,
  maxHeight,
  maxWidth,
  maxLength,
  maxWeightPerPiece,
  minDensity,
  maxDensity,
  requiredUnitSystem,
  convertToImperial,
  convertToMetric,
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [unitSystem, setUnitSystem] = useState('imperial');
  const [originalValues, setOriginalValues] = useState({ imperial: {}, metric: {} });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleChange = (key, field, value) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      newData[index] = { ...newData[index], [field]: Math.round(value || 0) };
      const alternateUnitSystem = unitSystem === 'imperial' ? 'metric' : 'imperial';
      let converted;
      if (unitSystem === 'imperial') {
        converted = {
          ...newData[index],
          height: Math.round(newData[index].height * 2.54),
          width: Math.round(newData[index].width * 2.54),
          length: Math.round(newData[index].length * 2.54),
          weight: Math.round(newData[index].weight * 0.453592),
        };
      } else {
        converted = {
          ...newData[index],
          height: Math.round(newData[index].height * 0.393701),
          width: Math.round(newData[index].width * 0.393701),
          length: Math.round(newData[index].length * 0.393701),
          weight: Math.round(newData[index].weight * 2.20462),
        };
      }
      setOriginalValues(prev => ({
        ...prev,
        [unitSystem]: {
          ...prev[unitSystem],
          [key]: { ...newData[index] },
        },
        [alternateUnitSystem]: {
          ...prev[alternateUnitSystem],
          [key]: { ...converted },
        },
      }));
      setDataSource(newData);
    }
  };

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    setOriginalValues(prev => {
      const newImperial = { ...prev.imperial };
      const newMetric = { ...prev.metric };
      delete newImperial[key];
      delete newMetric[key];
      return { imperial: newImperial, metric: newMetric };
    });
    if (selectedRowKey === key) {
      setSelectedRowKey(null);
    }
  };

  const handleAdd = () => {
    const newData = {
      key: Date.now(),
      height: 0,
      width: 0,
      length: 0,
      quantity: 1,
      weight: 0,
    };
    setDataSource([...dataSource, newData]);
    const alternateUnitSystem = unitSystem === 'imperial' ? 'metric' : 'imperial';
    let converted;
    if (unitSystem === 'imperial') {
      converted = {
        ...newData,
        height: Math.round(newData.height * 2.54),
        width: Math.round(newData.width * 2.54),
        length: Math.round(newData.length * 2.54),
        weight: Math.round(newData.weight * 0.453592),
      };
    } else {
      converted = {
        ...newData,
        height: Math.round(newData.height * 0.393701),
        width: Math.round(newData.width * 0.393701),
        length: Math.round(newData.length * 0.393701),
        weight: Math.round(newData.weight * 2.20462),
      };
    }
    setOriginalValues(prev => ({
      ...prev,
      [unitSystem]: {
        ...prev[unitSystem],
        [newData.key]: { ...newData },
      },
      [alternateUnitSystem]: {
        ...prev[alternateUnitSystem],
        [newData.key]: { ...converted },
      },
    }));
  };

  const convertUnits = (newUnitSystem) => {
    if (newUnitSystem === unitSystem) return;

    if (dataSource.length === 0) {
      setUnitSystem(newUnitSystem);
      return;
    }

    const newData = dataSource.map(record => {
      const key = record.key;
      const original = originalValues[newUnitSystem][key];
      if (original) {
        return { ...original };
      }
      return record;
    });

    setDataSource(newData);
    setUnitSystem(newUnitSystem);
  };

  const getLengthUnit = () => (unitSystem === 'imperial' ? 'in' : 'cm');
  const getWeightUnit = () => (unitSystem === 'imperial' ? 'lb' : 'kg');
  const getVolumeUnit = () => (unitSystem === 'imperial' ? 'ft³' : 'm³');
  const getDensityUnit = () => (unitSystem === 'imperial' ? 'lb/ft³' : 'kg/m³');

  const calculateTotalQuantity = () => {
    return dataSource.reduce((sum, record) => sum + record.quantity, 0);
  };

  const calculateRowVolume = (record) => {
    const volumeInCubicUnits = record.height * record.width * record.length * record.quantity;
    return (unitSystem === 'imperial' ? volumeInCubicUnits / 1728 : volumeInCubicUnits / 1000000).toFixed(3);
  };

  const calculateRowDensity = (record) => {
    const volumeInCubicUnits = record.height * record.width * record.length;
    const volume = unitSystem === 'imperial' ? volumeInCubicUnits / 1728 : volumeInCubicUnits / 1000000;
    return volume > 0 ? (record.weight / volume).toFixed(2) : 0;
  };

  const calculateTotalVolume = () => {
    const totalVolume = dataSource.reduce((sum, record) => {
      const volume = record.height * record.width * record.length * record.quantity;
      return sum + volume;
    }, 0);
    return (unitSystem === 'imperial' ? totalVolume / 1728 : totalVolume / 1000000).toFixed(3);
  };

  const calculateTotalWeight = () => {
    const totalWeight = dataSource.reduce((sum, record) => {
      return sum + record.weight * record.quantity;
    }, 0);
    return totalWeight.toFixed(3);
  };

  const calculateTotalDensity = () => {
    const totalWeight = dataSource.reduce((sum, record) => sum + record.weight * record.quantity, 0);
    const totalVolume = dataSource.reduce((sum, record) => sum + record.height * record.width * record.length * record.quantity, 0);
    const volumeInUnits = unitSystem === 'imperial' ? totalVolume / 1728 : totalVolume / 1000000;
    return volumeInUnits > 0 ? (totalWeight / volumeInUnits).toFixed(2) : 0;
  };

  const exceedsMaxDimension = (value, max, field) => {
    const currentInImperial = unitSystem === 'imperial' ? value : convertToImperial(value, field);
    const maxInImperial = requiredUnitSystem === 'imperial' ? max : convertToImperial(max, field);
    return currentInImperial > maxInImperial;
  };

  const exceedsMaxWeightPerPiece = (weight) => {
    const weightInImperial = unitSystem === 'imperial' ? weight : convertToImperial(weight, 'weight');
    const maxInImperial = requiredUnitSystem === 'imperial' ? maxWeightPerPiece : convertToImperial(maxWeightPerPiece, 'weight');
    return weightInImperial > maxInImperial;
  };

  const densityOutOfRange = (density) => {
    if (density <= 0) return false;
    const densityInImperial = unitSystem === 'imperial' ? density : convertToImperial(density, 'density');
    const minInImperial = requiredUnitSystem === 'imperial' ? minDensity : convertToImperial(minDensity, 'density');
    const maxInImperial = requiredUnitSystem === 'imperial' ? maxDensity : convertToImperial(maxDensity, 'density');
    return densityInImperial < minInImperial || densityInImperial > maxInImperial;
  };

  const totalQuantityMatches = () => {
    return calculateTotalQuantity() === requiredTotalQuantity;
  };

  const totalWeightMatches = () => {
    const currentTotalWeight = parseFloat(calculateTotalWeight());
    const requiredInCurrentUnits = unitSystem === requiredUnitSystem
      ? requiredTotalWeight
      : unitSystem === 'imperial'
        ? convertToImperial(requiredTotalWeight, 'weight')
        : convertToMetric(requiredTotalWeight, 'weight');
    return Math.abs(currentTotalWeight - requiredInCurrentUnits) < 0.01;
  };

  const getWarnings = () => {
    const warnings = [];
    dataSource.forEach((record, index) => {
      const rowNum = index + 1;
      if (exceedsMaxDimension(record.height, maxHeight, 'height')) {
        warnings.push(`Row ${rowNum}: Height (${record.height} ${getLengthUnit()}) exceeds max (${maxHeight} ${getLengthUnit()})`);
      }
      if (exceedsMaxDimension(record.width, maxWidth, 'width')) {
        warnings.push(`Row ${rowNum}: Width (${record.width} ${getLengthUnit()}) exceeds max (${maxWidth} ${getLengthUnit()})`);
      }
      if (exceedsMaxDimension(record.length, maxLength, 'length')) {
        warnings.push(`Row ${rowNum}: Length (${record.length} ${getLengthUnit()}) exceeds max (${maxLength} ${getLengthUnit()})`);
      }
      if (exceedsMaxWeightPerPiece(record.weight)) {
        warnings.push(`Row ${rowNum}: Weight (${record.weight} ${getWeightUnit()}) exceeds max per piece (${maxWeightPerPiece} ${getWeightUnit()})`);
      }
      const density = calculateRowDensity(record);
      if (densityOutOfRange(density)) {
        warnings.push(`Row ${rowNum}: Density (${density} ${getDensityUnit()}) is outside range (${minDensity} - ${maxDensity} ${getDensityUnit()})`);
      }
    });
    if (!totalQuantityMatches()) {
      warnings.push(`Total Quantity (${calculateTotalQuantity()}) does not match required (${requiredTotalQuantity})`);
    }
    if (!totalWeightMatches()) {
      warnings.push(`Total Weight (${calculateTotalWeight()} ${getWeightUnit()}) does not match required (${requiredTotalWeight} ${getWeightUnit()})`);
    }
    const totalDensity = calculateTotalDensity();
    if (densityOutOfRange(totalDensity)) {
      warnings.push(`Total Density (${totalDensity} ${getDensityUnit()}) is outside range (${minDensity} - ${maxDensity} ${getDensityUnit()})`);
    }
    return warnings;
  };

  const columns = [
    {
      title: () => (
        <span>
          Height {isMobile ? <br /> : ''} ({getLengthUnit()})
        </span>
      ),
      dataIndex: 'height',
      render: (text, record) => (
        <InputNumber
          min={0}
          value={text}
          step={1}
          onChange={(value) => handleChange(record.key, 'height', value)}
          style={{
            backgroundColor: exceedsMaxDimension(text, maxHeight, 'height') ? '#ffff99' : 'white',
          }}
        />
      ),
    },
    {
      title: () => (
        <span>
          Width {isMobile ? <br /> : ''} ({getLengthUnit()})
        </span>
      ),
      dataIndex: 'width',
      render: (text, record) => (
        <InputNumber
          min={0}
          value={text}
          step={1}
          onChange={(value) => handleChange(record.key, 'width', value)}
          style={{
            backgroundColor: exceedsMaxDimension(text, maxWidth, 'width') ? '#ffff99' : 'white',
          }}
        />
      ),
    },
    {
      title: () => (
        <span>
          Length {isMobile ? <br /> : ''} ({getLengthUnit()})
        </span>
      ),
      dataIndex: 'length',
      render: (text, record) => (
        <InputNumber
          min={0}
          value={text}
          step={1}
          onChange={(value) => handleChange(record.key, 'length', value)}
          style={{
            backgroundColor: exceedsMaxDimension(text, maxLength, 'length') ? '#ffff99' : 'white',
          }}
        />
      ),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      render: (text, record) => (
        <InputNumber
          min={1}
          value={text}
          step={1}
          onChange={(value) => handleChange(record.key, 'quantity', value || 1)}
        />
      ),
    },
    {
      title: () => (
        <span>
          Weight {isMobile ? <br /> : ''} ({getWeightUnit()})
        </span>
      ),
      dataIndex: 'weight',
      render: (text, record) => (
        <InputNumber
          min={0}
          value={text}
          step={1}
          onChange={(value) => handleChange(record.key, 'weight', value)}
          style={{
            backgroundColor: exceedsMaxWeightPerPiece(text) ? '#ffff99' : 'white',
          }}
        />
      ),
    },
    {
      title: () => (
        <span>
          Total Volume {isMobile ? <br /> : ''} ({getVolumeUnit()})
        </span>
      ),
      dataIndex: 'totalVolume',
      render: (_, record) => calculateRowVolume(record),
    },
    {
      title: () => (
        <span>
          Density {isMobile ? <br /> : ''} ({getDensityUnit()})
        </span>
      ),
      dataIndex: 'density',
      render: (_, record) => {
        const density = calculateRowDensity(record);
        return (
          <span style={{ color: densityOutOfRange(density) ? 'red' : 'inherit' }}>
            {density}
          </span>
        );
      },
    },
    {
      title: 'Remove',
      dataIndex: 'remove',
      render: (_, record) => (
        <DeleteOutlined
          style={{ cursor: 'pointer', color: '#ff4d4f' }}
          onClick={() => handleDelete(record.key)}
        />
      ),
    },
  ];

  const BoxSketch = ({ boxes, containerWidth, selectedKey }) => {
    const mountRef = useRef(null);
    const sceneRef = useRef(null);
    const cameraRef = useRef(null);
    const rendererRef = useRef(null);
    const controlsRef = useRef(null);
    const animationFrameId = useRef(null);

    useEffect(() => {
      const scene = new THREE.Scene();
      scene.background = new THREE.Color(0xf0f0f0);
      sceneRef.current = scene;

      const initialHeight = containerWidth * 1.2;
      const camera = new THREE.PerspectiveCamera(45, containerWidth / initialHeight, 0.1, 10000);
      camera.position.set(5, 5, 5);
      camera.lookAt(0, 0, 0);
      cameraRef.current = camera;

      const renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(containerWidth, initialHeight);
      rendererRef.current = renderer;
      mountRef.current.appendChild(renderer.domElement);

      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.05;
      controls.maxDistance = 1000;
      controlsRef.current = controls;

      const ambientLight = new THREE.AmbientLight(0x404040, 1.5);
      scene.add(ambientLight);
      const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
      directionalLight.position.set(5, 5, 5);
      scene.add(directionalLight);

      const animate = () => {
        controls.update();
        renderer.render(scene, camera);
        animationFrameId.current = requestAnimationFrame(animate);
      };
      animationFrameId.current = requestAnimationFrame(animate);

      return () => {
        if (animationFrameId.current) {
          cancelAnimationFrame(animationFrameId.current);
        }
        if (mountRef.current && renderer.domElement) {
          mountRef.current.removeChild(renderer.domElement);
        }
        scene.children.forEach(child => {
          if (child.geometry) child.geometry.dispose();
          if (child.material) child.material.dispose();
        });
        while (scene.children.length > 0) {
          scene.remove(scene.children[0]);
        }
        controls.dispose();
        renderer.dispose();
      };
    }, [containerWidth]);

    useEffect(() => {
      if (!sceneRef.current || !cameraRef.current || !rendererRef.current || !controlsRef.current) return;

      sceneRef.current.children.forEach(child => {
        if (child.type === 'Mesh' || child.type === 'LineSegments') {
          sceneRef.current.remove(child);
          if (child.geometry) child.geometry.dispose();
          if (child.material) child.material.dispose();
        }
      });

      if (boxes.length > 0) {
        const columns = Math.min(2, boxes.length);
        const boxesPerColumn = Math.ceil(boxes.length / columns);

        let maxWidthLeft = 0;
        let maxWidthRight = 0;
        let maxHeightLeft = 0;
        let maxHeightRight = 0;
        const heightsLeft = [];
        const heightsRight = [];

        boxes.forEach((box, index) => {
          const h = unitSystem === 'metric' ? box.height * 0.393701 : box.height;
          const w = unitSystem === 'metric' ? box.width * 0.393701 : box.width;
          const col = index % 2;
          if (col === 0) {
            maxWidthLeft = Math.max(maxWidthLeft, w);
            heightsLeft.push(h);
            maxHeightLeft = heightsLeft.reduce((sum, height) => sum + height, 0) + (heightsLeft.length - 1);
          } else {
            maxWidthRight = Math.max(maxWidthRight, w);
            heightsRight.push(h);
            maxHeightRight = heightsRight.reduce((sum, height) => sum + height, 0) + (heightsRight.length - 1);
          }
        });

        const totalUnscaledWidth = maxWidthLeft + (columns > 1 ? maxWidthRight + 1 : 0);
        const totalUnscaledHeight = Math.max(maxHeightLeft, maxHeightRight);

        const targetWidth = containerWidth * 0.8;
        const scale = totalUnscaledWidth > 0 ? targetWidth / totalUnscaledWidth : 1;

        const spacing = 10;
        const scaledHeight = totalUnscaledHeight * scale + (Math.max(heightsLeft.length, heightsRight.length) - 1) * spacing;
        const canvasHeight = Math.max(containerWidth * 1.2, scaledHeight * 1.2);

        cameraRef.current.aspect = containerWidth / canvasHeight;
        cameraRef.current.updateProjectionMatrix();
        rendererRef.current.setSize(containerWidth, canvasHeight);

        const centerX = (maxWidthLeft * scale) / 2 + (columns > 1 ? (maxWidthRight * scale) / 2 + scale : 0);
        const centerY = scaledHeight / 2;

        let yLeft = centerY;
        let yRight = centerY;

        boxes.forEach((box, index) => {
          const h = (unitSystem === 'metric' ? box.height * 0.393701 : box.height) * scale;
          const w = (unitSystem === 'metric' ? box.width * 0.393701 : box.width) * scale;
          const l = (unitSystem === 'metric' ? box.length * 0.393701 : box.length) * scale;

          const geometry = new THREE.BoxGeometry(w, h, l);
          const isSelected = box.key === selectedKey;
          const material = new THREE.MeshPhongMaterial({
            color: isSelected ? 0xffa500 : 0xd2b48c,
            specular: 0x555555,
            shininess: 30,
          });
          const mesh = new THREE.Mesh(geometry, material);

          const edges = new THREE.EdgesGeometry(geometry);
          const edgeMaterial = new THREE.LineBasicMaterial({ color: 0x000000, linewidth: 1 });
          const edgeLines = new THREE.LineSegments(edges, edgeMaterial);

          const col = index % 2;
          const x = col === 0 ? -centerX + w / 2 : centerX - w / 2;
          let y;
          if (col === 0) {
            y = yLeft - h / 2;
            yLeft -= h + spacing;
          } else {
            y = yRight - h / 2;
            yRight -= h + spacing;
          }

          mesh.position.set(x, y, 0);
          edgeLines.position.set(x, y, 0);

          sceneRef.current.add(mesh);
          sceneRef.current.add(edgeLines);
        });

        const maxSize = Math.max(totalUnscaledWidth * scale, scaledHeight) * 2;
        cameraRef.current.position.set(maxSize, maxSize, maxSize);
        cameraRef.current.lookAt(0, 0, 0);
        controlsRef.current.target.set(0, 0, 0);
      }
    }, [boxes, selectedKey, containerWidth]);

    return <div ref={mountRef} />;
  };

  return (
    <Row gutter={[24, 24]}>
      <Col xs={24} md={16}>
        <div style={{ marginBottom: 16 }}>
          <Space direction="vertical">
            <Select
              value={unitSystem}
              onChange={(value) => convertUnits(value)}
              style={{ width: 120 }}
            >
              <Option value="imperial">Imperial (in/lb)</Option>
              <Option value="metric">Metric (cm/kg)</Option>
            </Select>
            <Text strong style={{ color: totalQuantityMatches() ? 'inherit' : 'red' }}>
              Total Quantity: {calculateTotalQuantity()}
            </Text>
            <Text strong style={{ color: totalWeightMatches() ? 'inherit' : 'red' }}>
              Total Weight: {calculateTotalWeight()} {getWeightUnit()}
            </Text>
            <Text strong>
              Total Volume: {calculateTotalVolume()} {getVolumeUnit()}
            </Text>
            <Text strong style={{ color: densityOutOfRange(calculateTotalDensity()) ? 'red' : 'inherit' }}>
              Total Density: {calculateTotalDensity()} {getDensityUnit()}
            </Text>
          </Space>
        </div>
        <Table
          dataSource={dataSource}
          columns={columns}
          rowClassName={(record) =>
            record.key === selectedRowKey ? 'ant-table-row-selected' : ''
          }
          onRow={(record) => ({
            onClick: () => setSelectedRowKey(record.key),
          })}
          pagination={false}
        />
        <Button
          onClick={handleAdd}
          type="primary"
          style={{ marginTop: 16 }}
        >
          Add Row
        </Button>
        <div style={{ marginTop: 16 }}>
          {getWarnings().map((warning, index) => (
            <Text key={index} type="danger" style={{ display: 'block' }}>
              {warning}
            </Text>
          ))}
        </div>
      </Col>
      <Col xs={24} md={8} style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '100%', maxWidth: 300 }}>
          <h3>Box Previews</h3>
          {dataSource.length > 0 ? (
            <BoxSketch boxes={dataSource} containerWidth={300} selectedKey={selectedRowKey} />
          ) : (
            <p>Add boxes to see previews</p>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default BoxDimensionsForm;