import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Row, Col, Card, Button } from 'antd'
import { sectionStyle, cardHeaderStyle, cardBodyStyle, cardBodyHidden, reducedMarginFormItem } from '../formStyles';
import { ModalNewParticipant } from './ModalNewParticipant';
import { AcsApi } from '../../api/AcsApi';

const { Option } = Select;

export const BookingParticipant = ({ form, title, name, listParticipants=[]}) => {

    //const [listParticipants, setlistParticipants] = useState([]);
    const [selectParticipant, setselectParticipant] = useState({});

    // Function to show/hide Shipper section
    const [showParticipantInfo, setShowParticipantInfo] = useState(false);
    const [isModalVisible2, setIsModalVisible2] = useState(false);

    let prev = localStorage.getItem('agent_select');

        if(prev !== Select){
            //getParticipants(Select);
            prev = Select; 
        }
        
    
    
    
    // const getParticipants = async ( agent ) => {
    
    //     if(agent === '') return;
        
    //     try {    
    //         const respuesta = await AcsApi.get(`/acs/participants/v1/listext/${agent}`);
    //         setlistParticipants(respuesta.data);
            
    //     } catch (error) {
    //         console.log(error);        
    //     }    
    // }
        
    //useEffect( () => {}, [listAgents]);    
    //useEffect( () => {}, [selectParticipant]);

    const onParticipantSelect = (id) => {

        const select = listParticipants.find(partpnt => partpnt.id === id);
        setselectParticipant(select);

        form.setFieldsValue({
            [name]: {
                address: select?.Data?.address?.address || '',
                city: select?.Data?.address?.city || '',
                country: select?.Data?.address?.countryCode || '',
                name: select?.Data?.name,
                postalCode: select?.Data?.address?.zipCode || '',
                state: select?.Data?.address?.city || '',

            }
        });
    }


    // const handleCustomerCodeChange = (value) => {
    //     const selectedCustomer = participants.find(customer => customer.code === value);
    //     if (selectedCustomer) {
    //         setParticipant(selectedCustomer.name);
    //         // Set the customer name in the form
    //         form.setFieldsValue({
    //             [name]: {
    //                 name: selectedCustomer.name,
    //                 address: selectedCustomer.address,
    //                 city: selectedCustomer.city,
    //                 state: selectedCustomer.state,
    //                 country: selectedCustomer.country,
    //                 postalCode: selectedCustomer.zip
    //             }
    //         });
    //     } else {
    //         setParticipant('');
    //         form.setFieldsValue({ shpName: '', address: '', city: '' }); // Clear the form fields if no customer is selected
    //     }
    // };


    const showModalNew = () => {
        setIsModalVisible2(true);
    };

    return (
        <>
            {/* Section 3: Shipper Information with clickable title */}
            <Card
                title={
                    <div>

                        <div
                            onClick={() => setShowParticipantInfo(!showParticipantInfo)}
                            style={{ cursor: 'pointer', color: showParticipantInfo ? 'inherit' : '#1890ff' }}
                        >
                            {title}
                        </div>

                        <div
                            style={{
                                float: 'right',
                                display: showParticipantInfo ? 'flex' : 'none',
                                marginBottom: '1rem'
                            }}
                        >

                            <Button
                                onClick={showModalNew}
                                type="primary"
                            >
                                New
                            </Button>
                        </div>
                    </div>
                }
                style={sectionStyle}
                headStyle={cardHeaderStyle}
                bodyStyle={showParticipantInfo ? cardBodyStyle : cardBodyHidden}
                extra={null}
            >
                <Row gutter={[16, 8]}>
                    <Col xs={24} sm={24} md={12}>

                        <Form.Item
                            label="Code"
                            name={[name, 'code']}
                            style={reducedMarginFormItem}
                            //rules={[{ required: true, message: 'Please select a customer code!' }]}
                            hasFeedback
                        >
                            <Select
                                showSearch
                                placeholder="Select customer Code"
                                onChange={onParticipantSelect}
                            >
                                {
                                    (listParticipants.length > 0) &&
                                    Object.entries(listParticipants).map(agent => (
                                        <Option key={agent[1].id} value={agent[1].id}>{agent[1].id}</Option>
                                    ))
                                }

                            </Select>
                        </Form.Item>

                    </Col>

                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            label="Name"
                            name={[name, 'name']}
                            style={reducedMarginFormItem}
                        //rules={[{ required: true, message: 'Company name is required!' }]}
                        >
                            <Input
                                placeholder="Company name will auto-fill"
                                disabled
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 8]}>

                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            label="Street Address"
                            name={[name, 'address']}
                            style={reducedMarginFormItem}
                        //rules={[{ required: true, message: 'Please fill the address!' }]}
                        >
                            <Input
                                disabled
                                placeholder="Address auto-fill"
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12}>

                        <Form.Item
                            label="City"
                            name={[name, 'city']}
                            style={reducedMarginFormItem}
                        //rules={[{ required: true, message: 'City is required!' }]}
                        >
                            <Input
                                disabled
                                placeholder="City auto-fill"
                            />
                        </Form.Item>

                    </Col>
                </Row>
                {showParticipantInfo && (
                    <Row gutter={12}>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                                label="Postal Code"
                                name={[name, 'postalCode']}
                                style={reducedMarginFormItem}
                            //rules={[{ required: true, message: 'Please input the Postal Code' }]}
                            >
                                <Input  disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                                label="State"
                                name={[name, 'state']}
                                style={reducedMarginFormItem}
                            //rules={[{ required: true, message: 'Please input the name of the shipper!' }]}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                {showParticipantInfo && (
                    <Row gutter={12}>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                                label="Country Code"
                                name={[name, 'country']}
                                style={reducedMarginFormItem}
                            //rules={[{ required: true, message: 'Please input Country Code!' }]}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
            </Card>

            {
                (showParticipantInfo) && <ModalNewParticipant
                    isModalVisible={isModalVisible2}
                    setIsModalVisible={setIsModalVisible2}
                    create={name}
                />
            }
        </>
    )
}