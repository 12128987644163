import React, { useState } from 'react';
import { Form, InputNumber, Select, Button, Card } from 'antd';
import BoxDimensionsForm from './BoxDimensionsForm';

const { Option } = Select;

export const DimensionsMasterForm = () => {
  const [form] = Form.useForm();
  const [requiredUnitSystem, setRequiredUnitSystem] = useState('imperial');

  const onFinish = (values) => {
    console.log('Master Form Values:', values);
  };

  const convertToImperial = (value, field) => {
    if (requiredUnitSystem === 'imperial') return value;
    return field === 'weight' ? value * 2.20462 : field === 'density' ? value * 62.42796 : value * 0.393701;
  };

  const convertToMetric = (value, field) => {
    if (requiredUnitSystem === 'metric') return value;
    return field === 'weight' ? value * 0.453592 : field === 'density' ? value / 62.42796 : value * 2.54;
  };

  return (
    <Card title="Master Shipping Form" style={{ width: '100%', margin: '24px' }}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          requiredTotalQuantity: 0,
          requiredTotalWeight: 0,
          maxHeight: 0,
          maxWidth: 0,
          maxLength: 0,
          maxWeightPerPiece: 50, // Example max weight per piece in lb or kg
          minDensity: 0,
          maxDensity: 6, // Example range in imperial (lb/ft³)
          unitSystem: 'imperial',
        }}
      >
        <Form.Item label="Unit System" name="unitSystem">
          <Select onChange={setRequiredUnitSystem} style={{ width: 120 }}>
            <Option value="imperial">Imperial (in/lb)</Option>
            <Option value="metric">Metric (cm/kg)</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Required Total Quantity" name="requiredTotalQuantity" rules={[{ required: true, message: 'Please input required quantity!' }]}>
          <InputNumber min={0} step={1} />
        </Form.Item>
        <Form.Item label={`Required Total Weight (${requiredUnitSystem === 'imperial' ? 'lb' : 'kg'})`} name="requiredTotalWeight" rules={[{ required: true, message: 'Please input required weight!' }]}>
          <InputNumber min={0} step={0.1} />
        </Form.Item>
        <Form.Item label={`Max Height (${requiredUnitSystem === 'imperial' ? 'in' : 'cm'})`} name="maxHeight" rules={[{ required: true, message: 'Please input max height!' }]}>
          <InputNumber min={0} step={1} />
        </Form.Item>
        <Form.Item label={`Max Width (${requiredUnitSystem === 'imperial' ? 'in' : 'cm'})`} name="maxWidth" rules={[{ required: true, message: 'Please input max width!' }]}>
          <InputNumber min={0} step={1} />
        </Form.Item>
        <Form.Item label={`Max Length (${requiredUnitSystem === 'imperial' ? 'in' : 'cm'})`} name="maxLength" rules={[{ required: true, message: 'Please input max length!' }]}>
          <InputNumber min={0} step={1} />
        </Form.Item>
        <Form.Item label={`Max Weight Per Piece (${requiredUnitSystem === 'imperial' ? 'lb' : 'kg'})`} name="maxWeightPerPiece" rules={[{ required: true, message: 'Please input max weight per piece!' }]}>
          <InputNumber min={0} step={0.1} />
        </Form.Item>
        <Form.Item label={`Min Density (${requiredUnitSystem === 'imperial' ? 'lb/ft³' : 'kg/m³'})`} name="minDensity" rules={[{ required: true, message: 'Please input min density!' }]}>
          <InputNumber min={0} step={0.1} />
        </Form.Item>
        <Form.Item label={`Max Density (${requiredUnitSystem === 'imperial' ? 'lb/ft³' : 'kg/m³'})`} name="maxDensity" rules={[{ required: true, message: 'Please input max density!' }]}>
          <InputNumber min={0} step={0.1} />
        </Form.Item>
        <Form.Item shouldUpdate>
        {() => {
          const { requiredTotalQuantity, requiredTotalWeight, maxHeight, maxWidth, maxLength, maxWeightPerPiece, minDensity, maxDensity } = form.getFieldsValue();
          return (
            <BoxDimensionsForm
              requiredTotalQuantity={requiredTotalQuantity}
              requiredTotalWeight={requiredTotalWeight}
              maxHeight={maxHeight}
              maxWidth={maxWidth}
              maxLength={maxLength}
              maxWeightPerPiece={maxWeightPerPiece}
              minDensity={minDensity}
              maxDensity={maxDensity}
              requiredUnitSystem={requiredUnitSystem}
              convertToImperial={convertToImperial}
              convertToMetric={convertToMetric}
            />
          );
        }}
      </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit Master Form
          </Button>
        </Form.Item>
      </Form>

    </Card>
  );
};

export default DimensionsMasterForm;
