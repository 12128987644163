import React, { useEffect } from 'react';
import { Form, Input, Button, message} from 'antd';
import { newParticipant } from './helpers/newParticipant';


export const FormNewParticipant = ({create, setModalVisible}) => {

    let a_crear = {"accountNumber": '', "additionalName": '', "address": {"address": '', "city": '',
                    "countryCode": '', "additionalAddress": '', "stateOrProvince": '', "zipCode": ''},
                    "contact": {"faxNumber": '', "phoneNumber": '' }, "name": '' };

    const onCreate = async ({accountNumber, additionalName, address, city, countryCode, additionalAddress, 
                       stateOrProvince, zipCode, faxNumber, phoneNumber, name }) => {

    const AgentSelect = localStorage.getItem('agent_select');

    if(AgentSelect === ''){
        message.error('Select Agent');
        return;
    }

        a_crear = {
            accountNumber, 
            additionalName,
            "address": {
                address,
                city,
                countryCode,
                additionalAddress,
                stateOrProvince,
                zipCode
            },
            "contact": {
                faxNumber,
                phoneNumber
            },
            name
        }
          
        // const obj = {[create]: a_crear}           
        const registry = await newParticipant(AgentSelect, a_crear);

        if(registry){
            message.success('Created successfull');
            setModalVisible(false);
        }
        else {
            message.error('Something Wrong');
        }
      
    }

    useEffect( () => {        
        return () => { 
             a_crear = {"accountNumber": '', "additionalName": '', "address": {"address": '', "city": '',
                "countryCode": '', "additionalAddress": '', "stateOrProvince": '', "zipCode": ''},
                "contact": {"faxNumber": '', "phoneNumber": '' }, "name": '' };
        }
    }, [a_crear]);

  return (
    <div style={{margin: '2rem 1rem 0 0'}}>

        <Form 
            onFinish={onCreate}
            fields={[
                {
                    name: ['accountNumber'],
                    value: a_crear?.accountNumber
                },
                {
                    name: ['additionalName'],
                    value: a_crear?.additionalName
                },
                {
                    name: ['accountNumber'],
                    value: a_crear?.accountNumber
                },
                {
                    name: ['address'],
                    value: a_crear?.address.address
                },
                {
                    name: ['city'],
                    value: a_crear?.address.city
                },
                {
                    name: ['countryCode'],
                    value: a_crear?.address.countryCode
                },
                {
                    name: ['additionalAddress'],
                    value: a_crear?.address.additionalAddress
                },
                {
                    name: ['stateOrProvince'],
                    value: a_crear?.address.stateOrProvince
                },
                {
                    name: ['zipCode'],
                    value: a_crear?.address.zipCode
                },
                {
                    name: ['faxNumber'],
                    value: a_crear?.address.faxNumber
                },
                {
                    name: ['phoneNumber'],
                    value: a_crear?.address.phoneNumber
                },
                {
                    name: ['name'],
                    value: a_crear?.name
                },

            ]}
        >  

            <Form.Item
                label="Account Number"
                name="accountNumber"               
                rules={[{ required: true, message: 'Required' }]}
            >
                <Input  />
            </Form.Item>

            <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Required' }]}
            >
                <Input value={a_crear?.name}/>
            </Form.Item>

            <Form.Item
                label="Additional Name"
                name="additionalName"
                //rules={[{ required: true, message: 'Required' }]}
            >
                <Input value={a_crear?.additionalName}/>
            </Form.Item>

            <Form.Item
                label="Street Address"
                name="address"
                rules={[{ required: true, message: 'Required' }]}
            >
                <Input value={a_crear?.address.address}/>
            </Form.Item>

            <Form.Item
                label="Additional Address"
                name="additionalAddress"
                //rules={[{ required: true, message: 'Required' }]}
            >
                <Input value={a_crear?.address.additionalAddress}/>
            </Form.Item>
        
            <Form.Item
                label="City"
                name="city"
                rules={[{ required: true, message: 'Required' }]}
            >
                <Input value={a_crear?.address.city}/>
            </Form.Item>

            <Form.Item
                label="Country Code"
                name="countryCode"
                rules={[{ required: true, message: 'Required' }]}
            >
                <Input value={a_crear?.address.countryCode} />
            </Form.Item>

            <Form.Item
                label="State"
                name="stateOrProvince"
                //rules={[{ required: true, message: 'Required' }]}
            >
                <Input value={a_crear?.address.stateOrProvince}/>
            </Form.Item>

            <Form.Item
                label="zipCode"
                name="zipCode"
                //rules={[{ required: true, message: 'Required' }]}
            >
                <Input value={a_crear?.address.zipCode}/>
            </Form.Item>

            <Form.Item
                label="fax Number"
                name="faxNumber"
                //rules={[{ required: true, message: 'Required' }]}
            >
                <Input value={a_crear?.faxNumber}/>
            </Form.Item>

            <Form.Item
                label="Phone Number"
                name="phoneNumber"
                //rules={[{ required: true, message: 'Required' }]}
            >
                <Input value={a_crear?.phoneNumber}/>
            </Form.Item>

            <div className='contenedor_btn'>                    
                <Form.Item >
                    <Button
                        type='primary'
                        htmlType='submit'
                        style={{
                            backgroundColor: '#1D2758',
                            color: 'white', 
                        }}
                    >
                        Create
                    </Button>
                </Form.Item>
            </div>
        
        </Form>
  </div>             
  )
}


