import React, { useContext, useEffect, useState } from 'react';
//import { Link } from 'react-router-dom';
import { Button, Form, Input, Select, message, Row, Col, Divider, Checkbox, Card, Modal, List } from 'antd'
//import { DownOutlined } from '@ant-design/icons';
//import { ReservaContext } from './context/reservaContext';
import { getCurrentDate } from './helpers/fechaActual';
//import { AvailabilityObj } from './helpers/VuelosDisponibles';
import { ApiContext } from '../../context/ApiContext';
//import { useWindow } from './hooks/useWindow';
import { Spinner } from '../ui/Spinner';
import { MsgAvailability } from '../ui/MsgAvailability';
//import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { formStyle, sectionStyle, cardHeaderStyle, reducedMarginFormItem } from '../formStyles';



const { Option } = Select;

export const FormBooking = ({ onFieldsChange, listado = [] }) => {

    const [showSpinner, setShowSpinner] = useState(false);
    //const [disableButton, setDisableButton] = useState(false);

    const [NotFligths, setNotFligths] = useState(false);

    const [auth, guardarAuth] = useContext(ApiContext);
    //const [reserva_init, setReserva_init] = useContext(ReservaContext);

    const serializeForm = (values) => {
        const formData = form.getFieldsValue();
        // Log or do something with formData
        console.log('Serialized Form Data:', values);
        // Example: Convert to JSON string
        const jsonData = JSON.stringify(formData);
        console.log('JSON String:', jsonData);
    };

    const [form] = Form.useForm();

    // Special Handling codes with 3-letter keys
    const bookingTypes = [
        { name: 'General Cargo', key: 'GEN' },
        { name: 'Perishables', key: 'PER' },
        { name: 'Flowers', key: 'PEF' },
        { name: 'Apparel', key: 'APP' },
    ];

    // Custom tag render to show both name and key
    const customTagRender = (props) => {
        const { label, value, closable, onClose } = props;
        const type = bookingTypes.find(t => t.key === value) || { name: value, key: value };

        return (
            <span
                style={{
                    background: '#f5f5f5',
                    padding: '0 7px',
                    marginRight: 8,
                    borderRadius: 2,
                    display: 'inline-flex',
                    alignItems: 'center',
                }}
            >
                {`(${type.key}) ${type.name}`}
                {closable && (
                    <span
                        onClick={onClose}
                        style={{ marginLeft: 4, cursor: 'pointer' }}
                    >
                        ×
                    </span>
                )}
            </span>
        );
    };

    return (
        <>
            <Card title="Basic Information" style={sectionStyle} headStyle={cardHeaderStyle}>
                <Row gutter={12}>

                    {/* Example: Two inputs in one Form.Item /}
                    <Col xs={24} sm={24} md={14}>
                        <Form.Item
                            label="AWB"
                            name="airwaybill"
                            style={reducedMarginFormItem}
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 14 }}
                        >
                            <Row gutter={0}>
                                <Col span={7}>
                                    <Form.Item
                                        name="prefix"
                                        noStyle
                                    >
                                        <Input defaultValue='279' disabled />
                                    </Form.Item>
                                </Col>
                                <Col span={2} style={{ textAlign: 'center', paddingTop: 0 }}>-</Col>
                                <Col span={15}>
                                    <Form.Item
                                        name="serial"
                                        noStyle
                                        rules={[
                                            { max: 8, message: 'AWB must be max 8 digits' },
                                            {
                                                pattern: /^[0-9]+$/,
                                                message: 'can only include numbers.',
                                            },
                                        ]}
                                        hasFeedback
                                    >
                                        <Input placeholder="Number"
                                            type='text'
                                            min={30}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            label="Agent"
                            name="agent"
                            style={reducedMarginFormItem}
                            rules={[
                                {
                                    required: true,
                                    message: 'Required',
                                }
                            ]}
                            hasFeedback 
                        >

                            {/* Combining Input with Select /}
                            <Select
                                showSearch
                                // onChange={() => onFieldsChange()} // Trigger validation check on change
                                onChange={(value) => {
                                    onFieldsChange()
                                    localStorage.setItem('agent_select', value);
                                }} // Trigger validation check on change
                                placeholder="Select or enter Agent"

                                //filterOption={(input, option) =>
                                //    option.children.indexOf(input.toLowerCase()) >= 0 //toLowerCase()
                                //}
                                //style={{ width: '100%' }}
                            >
                                {
                                     (auth.agents) &&  
                                        Object.entries(auth.agents || {}).map(([clave, valor]) => (
                                        <Option key={clave} value={clave}>{valor}</Option>
                                    ))
                                }
                                
                            </Select>

                        </Form.Item>
                    </Col>*/}
                    <Col xs={24} sm={24} md={8}>
                        <Form.Item
                            label="Origin"
                            name="originAirportCode"
                            style={reducedMarginFormItem}
                            rules={[
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]}
                            hasFeedback
                        >
                            <Select
                                showSearch
                                onChange={() => onFieldsChange()} // Trigger validation check on change
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    Object.entries(auth.origins || {}).map(([clave, valor]) => (
                                        <Option key={clave} value={clave}>{valor}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                        <Form.Item
                            label="Destination"
                            name="destinationAirportCode"
                            style={reducedMarginFormItem}
                            rules={[
                                {
                                    required: true,
                                    message: 'Required',
                                }
                            ]}
                            hasFeedback
                        >
                            <Select
                                showSearch
                                onChange={() => onFieldsChange()} // Trigger validation check on change
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    Object.entries(auth.destinations || {}).map(([clave, valor]) => (
                                        <Option key={clave} value={clave}>{valor}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                        <Form.Item
                            label="Product"
                            name="product"
                            style={reducedMarginFormItem}
                            rules={[
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]}
                            hasFeedback
                        >
                            <Select onChange={() => onFieldsChange()} >
                                {
                                    Object.entries(auth.products).map(([clave, valor]) => (
                                        <Option key={clave} value={clave}>{valor}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            label="Nature Of Goods"
                            name="natureOfGoods"
                            style={reducedMarginFormItem}
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 14 }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]}
                        >

                            <Input placeholder="Description of the Goods"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="shc"
                            label="Special Handling Codes"
                            rules={[{ required: true, message: 'Please select at least one type' }]}
                        >
                            <Select
                                mode="tags"
                                style={{ width: '100%' }}
                                placeholder="Select or add Handling Codes"
                                allowClear
                                tagRender={customTagRender}
                                dropdownRender={(menu) => (
                                    <>
                                        {menu}
                                        <div style={{ padding: 8 }}>
                                            <span style={{ color: '#999' }}>
                                                Type to add custom types (will use first 3 letters as key)
                                            </span>
                                        </div>
                                    </>
                                )}
                                onChange={(values) => {
                                    // Convert new custom types to 3-letter keys
                                    const processedValues = values.map(value => {
                                        const foundType = bookingTypes.find(t => t.key === value);
                                        if (foundType) return value;
                                        return value.substring(0, 3).toUpperCase();
                                    });
                                    form.setFieldsValue({ types: processedValues });
                                }}
                            >
                                {bookingTypes.map((type) => (
                                    <Option key={type.key} value={type.key}>
                                        {type.name} ({type.key})
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={12}> {/* Reduced vertical gutter from 16 to 12 */}
                    <Col xs={12} sm={12} md={6}>
                        <Form.Item
                            label='Weight'
                            name='weight'
                            style={reducedMarginFormItem}
                            rules={[
                                {
                                    required: true,
                                    message: 'Required',
                                },
                                {
                                    pattern: /^[0-9]+$/,
                                    message: 'can only include numbers.',
                                },
                                {
                                    // validacion para que el peso no supere 2000 lb
                                    validator: (_, value) => {
                                        /*if (value && Number(value) > 2000) {
                                            return Promise.reject(new Error('Max 2000 LB'))
                                        }*/
                                        return Promise.resolve()
                                    }
                                }

                            ]}
                            hasFeedback
                        >
                            <Input style={{ width: 130, }} type='number' placeholder='LB*'
                                onChange={() => onFieldsChange()} // Trigger validation check on change
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={12} sm={12} md={6}>
                        <Form.Item
                            label="Pieces"
                            name="pieces"
                            style={reducedMarginFormItem}
                            rules={[
                                {
                                    required: true,
                                    message: 'Required',
                                },
                                {
                                    pattern: /^[0-9]+$/,
                                    message: 'can only include numbers.',
                                },
                                { max: 3 }
                            ]}
                            hasFeedback
                        >
                            <Input style={{ width: 130, }} type='number' placeholder='pieces' />
                        </Form.Item>
                    </Col>

                    <Col xs={12} sm={12} md={6}>
                        <Form.Item
                            label="Departure"
                            name="departureOn"
                            style={reducedMarginFormItem}
                            rules={[
                                { required: true, message: 'Required' }
                            ]}

                        >
                            <Input style={{ width: 130 }} type='date' min={getCurrentDate()}
                                onChange={() => onFieldsChange()} // Trigger validation check on change
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={12} sm={12} md={6}>
                        <Form.Item
                            label="Arrival"
                            name="arrivalOn"

                        >
                            <Input style={{ width: 130 }} type='date' min={getCurrentDate()} />
                        </Form.Item>
                    </Col>
                </Row>
            </Card>

            {
                (showSpinner) && <div><Spinner /></div>
            }

            {
                (listado.length === 0 && NotFligths) && <MsgAvailability />
            }
        </>
    )
}