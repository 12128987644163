import React, { useState } from 'react';
import { Form, Input, Button, Card, Table, Row, Col } from 'antd';
import UserSettingsForm from './UserSettingsForm';
import * as s from '../formStyles';
import axios from 'axios';
import {AcsApi} from '../../api/AcsApi';

export const UserSettingsMain = () => {
  const [searchForm] = Form.useForm();
  const [bookings, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showUserForm, setShowUserForm] = useState(false);
  //const token = localStorage.getItem('token');

  const  onSearch = async (values) => {

        const params = new URLSearchParams();
    
        (values.username)&&params.append('username', values.username);
        (values.name)&&params.append('name', values.name);
        (values.lastname)&&params.append('lastname', values.lastname);
        (values.email)&&params.append('email', values.email);
        (values.company)&&params.append('company', values.company);

        const response = await AcsApi.get('/acs/users/v1/list', {
            params,
        });
        console.log(response);
        console.log(response.data);
        setUsers(response.data); 

    // Simulated API call
    // const mockUsers = [
    //   { username: 'JLONDON', name: 'John', lastname: 'London', email: 'John@mymail.com', agents: ['UTST001', 'EXP'] },
    //   { username: 'JSMITH', name: 'Jane', lastname: 'Smith', email: 'Jane@Smith.ca', agents: ['UTST002'] },
    //   { username: 'CHRENDEL', name: 'Christian', lastname: 'Rendel', email: 'Bob@Lee.com', agents: ['UTST001', 'UTST002'] },
    // ].filter(user =>
    //   (!values.username || user.username.toLowerCase().includes(values.username.toLowerCase())) &&
    //   (!values.name || user.name.toLowerCase().includes(values.name.toLowerCase())) &&
    //   (!values.lastname || user.lastname.toLowerCase().includes(values.lastname.toLowerCase())) &&
    //   (!values.email || user.email.toLowerCase().includes(values.email.toLowerCase()))
    // );
    // setUsers(mockUsers);
  };

  const handleUserSubmit = (userData) => {
    console.log('User submitted:', userData);
    setSelectedUser(null);
    searchForm.submit();
  };

  const handleEditUser = (booking) => {
    setSelectedUser(booking);
    setShowUserForm(true);
  };

  const handleBack = () => {
    setSelectedUser(null);
    setShowUserForm(false);
  };

  const columns = [
    { title: 'ID', dataIndex: 'username', key: 'username' },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Lastname', dataIndex: 'lastname', key: 'lastname' },
    { title: 'E-Mail', dataIndex: 'email', key: 'email' },
    //{ title: 'Agents', dataIndex: 'agents', key: 'agents', render: types => types.join(', ') },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button onClick={() => handleEditUser(record)}>Edit</Button>
      ),
    },
  ];

  return (
    <div style={s.formStyle}>
      <Col span={24}>

        {showUserForm ? (
          <UserSettingsForm
            initialUser={selectedUser}
            onSubmit={handleUserSubmit}
            onBack={handleBack}
          />
        ) : (
          <Card
            title="Search Users"
            style={s.sectionStyle}
            headStyle={s.cardHeaderStyle}
          >
            <Form form={searchForm} layout="vertical" onFinish={onSearch}>
              <Row>
                <Col xs={24} sm={12} md={12}>
                  <Form.Item name="username" label="Username">
                    <Input placeholder="Enter Username" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <Form.Item name="email" label="E-Mail">
                    <Input placeholder="Enter E-Mail" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <Form.Item name="name" label="Name">
                    <Input placeholder="Enter Name" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <Form.Item name="lastname" label="Lastname">
                    <Input placeholder="Enter Lastname" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">Search</Button>
                  </Form.Item>
                </Col >
              </Row>
            </Form >
            <Table style={{padding: '0.5rem',}} dataSource={bookings} columns={columns} rowKey="id" />
          </Card >
        )}
      </Col >
    </div >
  );
};

export default UserSettingsMain;