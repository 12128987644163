import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReservaContext } from './context/reservaContext';
import { FormBooking } from './NewBooking-Form';
import { BookingParticipant } from './NewBooking-Participant';
import { FlightList } from './NewBooking-SelectFlights';
import { Button, Form, message, Card, Row, Col, Input, Select, Divider } from 'antd';
import { formStyle, sectionStyle, cardHeaderStyle, reducedMarginFormItem } from '../formStyles';
import { ApiContext } from '../../context/ApiContext';
import { Spinner } from '../ui/Spinner';
import { Alert } from '../ui/Alert';
import { useNavigate } from 'react-router-dom';
import { AcsApi, ApiCatch } from '../../api/AcsApi';

const { Option } = Select;

const formatFormData = (values) => {
    // Format the form data for the API

    const output =
    {
        agentAccountNumber: values.agent,
        airWaybill: {
            prefix: values.prefix ? values.prefix : '279',
            referenceType: 'AIR WAYBILL',
            serial: values.serial
        },
        shipper: {
            address: values.shipper?.address,
            city: values.shipper?.city,
            country: values.shipper?.country,
            name: values.shipper?.name,
            postalCode: values.shipper?.postalCode,
            state: values.shipper?.state,
        },
        consignee: {
            address: values.consignee.address,
            city: values.consignee.city,
            country: values.consignee.country,
            name: values.consignee.name,
            postalCode: values.consignee.postalCode,
            state: values.consignee.state,
        },
        destinationAirportCode: values.destinationAirportCode,
        natureOfGoods: values.natureOfGoods,
        originAirportCode: values.originAirportCode,
        pieces: Number(values.pieces) || 0,
        weight: { amount: Number(values.weight) || 0, unit: 'LB' },
        productCode: values.product,
        salesAreaCode: 'MIA',

        segments: values.flights ? values.flights.flatMap(flight =>
            flight.segments.map(s => ({
                destinationAirportCode: s.offload.code,
                originAirportCode: s.onload.code,
                pieces: Number(flight.pieces) || 0,
                weight: {
                    amount: Number(flight.weight) || 0,
                    unit: 'LB'
                },
                spaceAllocationCode: 'KK',
                transportMeans: {
                    carrierCode: s.transportMeans.carrier.code,
                    date: s.transportMeans.date,
                    number: s.transportMeans.transportNumber
                },
            }))
        ) : [],
    };

    if (!values?.shipper?.name) delete output.shipper;
    if (!values?.consignee?.name) delete output.consignee;
    return output;
};

export const NewBooking = () => {

    const token = localStorage.getItem('token');

    const [listado, setlistado] = useState([]);
    const [participants, setparticipants] = useState([]);
    const [disableButton, setDisableButton] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [NotFligths, setNotFligths] = useState(false);
    const [reserva_init, setReserva_init] = useContext(ReservaContext);
    const [auth, guardarAuth] = useContext(ApiContext);

    const navigate = useNavigate();

    const serializeForm = (values) => {
        const formData = form.getFieldsValue();
        // Log or do something with formData
        console.log('Serialized Form Data:', values);
        // Example: Convert to JSON string
        const jsonData = JSON.stringify(values);
        console.log('JSON String:', jsonData);
        return 'Serialized:' + jsonData;
    };

    const [form] = Form.useForm();
    const [personalInfoFilled, setPersonalInfoFilled] = React.useState(false);

    const onFinish = async (values) => {

        await Alert('You are about to confirm the booking',
            'AWB ' + reserva_init.airWaybill.prefix + '-' + reserva_init.airWaybill.serial +
            '\nfor ' + reserva_init.agentAccountNumber,
            'success');

        //setShowSpinner(true);
        console.log('Form values:', values);
        const output = formatFormData(values);
        console.log('Output:', output);
        return;

        await AcsApi.post('/booking/acs/bookings', output, {
            headers: {
                sessionid: token,
            }
        })
            .then(response => {
                console.log('Success:', response.data);
                Alert('The Booking is confirmed',
                    'AWB ' + response.data.airWaybill.prefix + '-' + response.data.airWaybill.serial +
                    '\nfor ' + response.data.agent.accountNumber,
                    'success');

                setReserva_init({
                    agentAccountNumber: '',
                    airWaybill: {
                        prefix: "279",
                        referenceType: 'AIR WAYBILL'
                    },
                    destinationAirportCode: '',
                    natureOfGoods: '',
                    originAirportCode: '',
                    pieces: '',
                    segments: [],
                    weight: { amount: '', unit: 'LB' },
                    productCode: '',
                    salesAreaCode: ''
                });
                localStorage.setItem('send', 'ok');
                setShowSpinner(false);
                navigate('/form');
            })
            .catch(error => {
                setShowSpinner(false);
                ApiCatch(error);
            });
    }

    //Enables Add Flight field once all requirements are met
    const checkPersonalInfoFilled = () => {
        const values = form.getFieldsValue([
            'agent',
            'product',
            'originAirportCode',
            'destinationAirportCode',
            'weight',
            'departureOn'
        ]);

        console.log('Serialized Form Data:', form.getFieldsValue());
        const allFilled = values.agent &&
            values.product &&
            values.originAirportCode &&
            values.destinationAirportCode &&
            values.weight &&
            values.departureOn;
        setPersonalInfoFilled(allFilled);
    };

        const getParticipants = async ( agent ) => {
        
            if(agent === '') return;
            console.log('Getting participants for agent:', agent);
            setShowSpinner(true);
            try {    
                const respuesta = await AcsApi.get(`/acs/participants/v1/listext/${agent}`);
                //setlistParticipants(respuesta.data);
                setparticipants(respuesta.data);
                console.log('Participants:', participants);
                
            } catch (error) {
                console.log(error);
            }    
            finally{
                setShowSpinner(false);
            }
        }

    return (
        <>
            <Form
                name="form"
                layout="vertical"
                style={formStyle}
                onFinish={onFinish}
                colon={false}
                form={form}
                onLoadStart={console.log('Auth values:', auth)}
            >
                <Card title="New Booking" style={sectionStyle} headStyle={cardHeaderStyle}>
                    <Row gutter={12}>

                        {/* Example: Two inputs in one Form.Item */}
                        <Col xs={24} sm={12} md={12}>
                            <Form.Item
                                label="AWB"
                                name="airwaybill"
                                style={reducedMarginFormItem}
                                labelCol={{ span: 10 }}
                                wrapperCol={{ span: 14 }}
                            >
                                <Row gutter={0}>
                                    <Col span={7}>
                                        <Form.Item
                                            name="prefix"
                                            noStyle
                                        >
                                            <Input defaultValue='279' disabled />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2} style={{ textAlign: 'center', paddingTop: 0 }}>-</Col>
                                    <Col span={15}>
                                        <Form.Item
                                            name="serial"
                                            noStyle
                                            rules={[
                                                { max: 8, message: 'AWB must be max 8 digits' },
                                                {
                                                    pattern: /^[0-9]+$/,
                                                    message: 'can only include numbers.',
                                                },
                                            ]}
                                            hasFeedback
                                        >
                                            <Input placeholder="Number"
                                                type='text'
                                                min={30}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12}>
                            <Form.Item
                                label="Agent"
                                name="agent"
                                style={reducedMarginFormItem}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    }
                                ]}
                                hasFeedback
                            >

                                {/* Combining Input with Select */}
                                <Select
                                    showSearch
                                    // onChange={() => onFieldsChange()} // Trigger validation check on change
                                    onChange={(value) => {
                                        checkPersonalInfoFilled();
                                        //onFieldsChange()
                                        localStorage.setItem('agent_select', value);
                                        getParticipants(value);
                                    }} // Trigger validation check on change
                                    placeholder="Select or enter Agent"

                                //filterOption={(input, option) =>
                                //    option.children.indexOf(input.toLowerCase()) >= 0 //toLowerCase()
                                //}
                                //style={{ width: '100%' }}
                                >
                                    {
                                        (auth.agents) &&
                                        Object.entries(auth.agents || {}).map(([clave, valor]) => (
                                            <Option key={clave} value={clave}>{valor}</Option>
                                        ))
                                    }

                                </Select>

                            </Form.Item>
                        </Col>
                    </Row>
                </Card> 

                <FormBooking onFieldsChange={checkPersonalInfoFilled} listado={listado} />
                <BookingParticipant form={form} 
                    title='Shipper Information' 
                    name='shipper' 
                    listParticipants={participants}
                />
                <BookingParticipant 
                    form={form} 
                    title='Consignee Information' 
                    name='consignee' 
                    listParticipants={participants}
                />
                <FlightList form={form} list={listado} personalInfoFilled={personalInfoFilled} />

                <div className='ContenedorSearchFligths'>
                    <Form.Item >
                        <Button
                            id='btn_searchFlights'
                            type='primary'
                            htmlType='submit'
                            style={{
                                backgroundColor: '#1D2758',
                                color: 'white',
                                margin: '0.5rem 0rem',
                            }}
                            disabled={disableButton}
                        >
                            Confirm Booking
                        </Button>
                    </Form.Item>
                </div>
            </Form>
            {
                (showSpinner) && <div><Spinner /></div>
            }
        </>
    )
}