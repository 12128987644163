import React from 'react';
import { Button, Modal } from 'antd';
import { FormNewParticipant } from './NewBooking-NewParticipant';



export const ModalNewParticipant = ({ isModalVisible, setIsModalVisible, create }) => {

  const handleEmployeeCancel = () => {
      setIsModalVisible(false);
  };

  return (
    <>               
        <Modal
            title={`Create new ${create}`}
            open={isModalVisible}  
            onCancel={handleEmployeeCancel}
            width={600}  
            footer={[               
                <Button 
                    key={1}
                    onClick={handleEmployeeCancel} 
                    style={{backgroundColor: 'red', color: 'white'}}
                >
                    Cancel
                </Button>              
            ]}          
        >
          <FormNewParticipant 
            create={create} 
            setModalVisible={setIsModalVisible}
          />

        </Modal>
    </>
  )
}


