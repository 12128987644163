
// Style for Forms
export const formStyle = {
    maxWidth: '1200px', 
    height: '100%', 
    margin: '0 auto', 
    backgroundColor: '#eee', 
    padding: '1rem 0.2rem' 
};

// Style for rounded rectangles
export const sectionStyle = {
    borderRadius: '8px',
    padding: '0px',
    marginBottom: '8px',
    boxShadow: '2px 5px 10px rgba(0, 0, 0, 0.5)',
    backgroundColor: '#f5f5f5'
};

// Style for card headers
export const cardHeaderStyle = {
    backgroundColor: '#d0d2e5', // Light blue background
    minHeight: '2rem',
    //color: '#1890ff' // Ant Design's primary blue color for text
};

// Style for card body
export const cardBodyStyle = {
    //backgroundColor: '#d0d2e5', // Light grey background
    minHeight: '2rem',
    //display: 'none',
    //color: '#1890ff' // Ant Design's primary blue color for text
};
// Style for card body
export const cardBodyHidden = {
    //backgroundColor: '#d0d2e5', // Light grey background
    //minHeight: '2rem',
    display: 'none',
    //color: '#1890ff' // Ant Design's primary blue color for text
};

// Custom style to reduce spacing between form items
export const reducedMarginFormItem = {
    marginBottom: '8px', // Reduced from default 24px
    padding: '0px'
};